import WOW from 'wowjs';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const Home = () => {

    const [activeSection, setActiveSection] = useState('');
    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
      };

    return (
        <div className='mr-bean-page'>
            <div>
                <Navbar activeSection={activeSection} />
                {/* Banner */}
                <VisibilitySensor onChange={() => setVisible('Banner')}>
                    <section className="banner-section" id="home">
                        <div className='animation-coin-1'><img className='buy-img' src={require("../../static/images/coin-1.png")} alt="" /></div>
                        <div className='animation-coin-2'><img className='buy-img' src={require("../../static/images/coin-1.png")} alt="" /></div>
                        <div className='animation-coin-3'><img className='buy-img' src={require("../../static/images/coin-1.png")} alt="" /></div>
                        <div className='animation-coin-4'><img className='buy-img' src={require("../../static/images/coin-1.png")} alt="" /></div>
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className='banner-left-area'>
                                        <div className="banner-text wow slideInLeft" data-wow-delay='100ms'>
                                            <h1><img className='buy-img' src={require("../../static/images/title-banner.png")} alt="" /></h1>
                                            <div className='banner-img'>
                                                <img className='buy-img' src={require("../../static/images/banner-img.png")} alt="" />
                                            </div>
                                            <HashLink className='buy-area' smooth to="#joinme">
                                            buy now
                                            </HashLink>
                                        </div>
                                        <div className='right-dog'>
                                            <img className='buy-img' src={require("../../static/images/dog-img.png")} alt="" />
                                        </div>
                                        {/* <div className="btn-area">
                                        <ul>                                    
                                            <li><HashLink to="#joinme" smooth className="btn-style-one"><span>join us</span></HashLink> </li>
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Toknomics Section */}
                <VisibilitySensor onChange={() => setVisible('Toknomics')}>
                    <section className='toknomics-sec' id="toknomics-sec">
                        <div className='auto-container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2><img src={require("../../static/images/toknomics-title.png")} alt="" /></h2>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='toknomics-area-boxes'>
                                        <div className='toknomics-box wow fadeInLeft'>
                                            <div className='img-box'>
                                                <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                                <div className='text-box'>
                                                    <h4>Token Address</h4>
                                                    <p>9gCJB....pUMp</p>
                                                    <CopyToClipboard text='9gCJBVuhT4uFtwaBVnB6GGcsC2wTYsJZm48REdspUMp' onCopy={onCopyText}>
                                                        <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                    </CopyToClipboard>
                                                    {copyStatus && <p style={{marginTop: '80px', fontSize: '9px'}}>Wallet Address Copied</p>}
                                                </div>
                                                {/* <div className='dog-img'>
                                                    <img className='buy-img' src={require("../../static/images/dog-img.png")} alt="" />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='toknomics-box wow fadeInUp'>
                                            <div className='img-box'>
                                                <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                                <div className='text-box'>
                                                    <h4>100% LP Burnt</h4>
                                                    <i className='icon'><img src={require("../../static/images/fire.png")} alt="" /></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='toknomics-box wow fadeInDown'>
                                            <div className='img-box'>
                                                <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                                <div className='text-box'>
                                                    <h4>Total Supply</h4>
                                                    <p>100 billion</p>
                                                    <i className='icon'><img src={require("../../static/images/doller.png")} alt="" /></i>
                                                </div>
                                                {/* <div className='dog-img'>
                                                    <img className='buy-img' src={require("../../static/images/dog-img.png")} alt="" />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='toknomics-box wow fadeInRight'>
                                            <div className='img-box'>
                                                <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                                <div className='text-box'>
                                                    <h4>Mint Authority <br />Revoked</h4>
                                                    <i className='icon'><img src={require("../../static/images/user.png")} alt="" /></i>
                                                </div>
                                                {/* <div className='dog-img'>
                                                    <img className='buy-img' src={require("../../static/images/dog-img.png")} alt="" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='car-animation'>
                            <img src={require("../../static/images/car-animation.gif")} alt="" />
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Animation Section */}
                <VisibilitySensor onChange={() => setVisible('Animation')}>
                    <section className='animation-sec' id="animation-sec">
                        <div className='animation-box'>
                            <img src={require("../../static/images/fish-animation.gif")} alt="" />
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Join Section */}
                <VisibilitySensor onChange={() => setVisible('Join')}>
                    <section className='join-sec' id="joinme">
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className="col-lg-7 col-md-12">
                                    <div className="heading-memes-area-left wow fadeIn">
                                        <div className='left-img'>
                                            <img src={require("../../static/images/left-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2 style={{position: 'relative'}} data-text="join the">join the</h2>
                                        <h3 style={{position: 'relative'}} data-text="community"> community </h3>
                                        <ul>
                                            <li>
                                                <a className='nav-link' href='https://t.me/MrBeansMeme' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' /></a>
                                            </li>
                                            <li>
                                                <a className='nav-link' href='https://x.com/MrBeansCoin' target='_blank'><img src={require('../../static/images/x.png')} alt='' /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>
            </div>
        </div >
    );
}

export default Home;